<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-subheader>
          <v-row>
            <v-col>Category List</v-col>

            <v-col cols="6">
              <v-btn class="mr-5" to="/admin/products" outlined>Products</v-btn>
              <v-btn to="/admin/products/category/add" color="success" outlined>Add Category</v-btn>
            </v-col>
          </v-row>
        </v-subheader>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <slot name="buttons"></slot>
          </v-card-title>
          <v-progress-linear :indeterminate="loading"></v-progress-linear>
          <v-data-table :headers="headers" :items="allProductCategories" :search="search">
            <template #item.active="{item}">
              <v-chip
                :color="item.active ? 'success': 'error'"
                small
              >{{item.active ? "Online": "Offline"}}</v-chip>
            </template>
            <template #item.action="{item}">
              <v-icon small class="mr-2" @click="editProductCategory(item.id)">edit</v-icon>
              <v-icon small @click="removeCategory(item.id)">delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ProductCategories",
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name"
        },
        { text: "Status", align: "left", value: "active" },
        { text: "Action", sortable: false, align: "left", value: "action" }
      ]
    };
  },
  computed: {
    ...mapGetters(["allProductCategories"])
  },
  methods: {
    ...mapActions(["bind", "delete"]),
    fetchCategory() {
      if (!this.allProductCategories) {
        this.bind({
          collection: "productCategories",
          data: "productCategories"
        })
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    },
    editProductCategory(id) {
      this.$router.replace("/admin/products/category/" + id);
    },
    removeCategory(id) {
      // Delete category
      this.delete({
        collection: "productCategories",
        doc: id
      });
    }
  },
  created() {
    this.loading = true;
    // Bind Product Catagories
    this.fetchCategory();
  }
};
</script>

<style></style>
